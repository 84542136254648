<template >
  <div class="container is-primary is-fullheight">
    <div class="is-primary">
      <form @submit.prevent="login"></form>
      <br />
      <div class="hero-body is-centered mb-5" >
        <h2 style="background-color: cadetblue;"
            class="is-family-sans-serif box title has-text-centered has-text-weight-semibold has-text-weight-semibold is-family-sans-serif is-4 has-text-white mt-5">
            CAPACITACIONES
        </h2> 
        <div class="box  container" style="background-color: royalblue;">
          <b-field label="Usuario" custom-class="is-family-sans-serif has-text-white">
            <b-input v-model="username"></b-input>
          </b-field>
          <b-field label="Contraseña" custom-class="is-family-sans-serif has-text-white">
              <b-input :type="passwordFieldType"
                  v-model="password"
                  password-reveal>
              </b-input>
              <button type="password" @click="switchVisibility">v</button>
          </b-field>
          <b-button
            type="is-success"
            size="is-medium is-fullwidth"
            :disabled="filled"
            v-on:click="doLogin"
            >Ingresar</b-button
          >
        </div>
      </div>
      <div class="hero-foot mb-5">
        <div class="tabs" style="    text-align-last: center;">
          <div class="container">
            <p class="is-size-7">POWER BY WOKEN</p>
              <div class="hero-head has-background-white">
                <div class="container is-size-4 mt-5">
                  <img
                    src="https://getonbrd-prod.s3.amazonaws.com/uploads/users/logo/7908/Woken-vertical.png"
                    alt="Logo Woken"
                    style="max-height: 50px"
                    class="is-rounded "
                  />
                  
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "LoginView",
  data: function () {
    return {
      username: "",
      password: "",
      passwordFieldType: "password"
    };
  },
  computed: {
    filled: function () {
      return !(this.username.length && this.password.length);
    },
  },
  methods: {
    ...mapActions({login: 'auth/login_user'}),
    doLogin: async function () {
      let username = this.username;
      let password = this.password;
      try {
        await this.login({ username, password });
        if (this.$store.getters["auth/isLogged"]) {
          this.$router.push("/form");
        }
      } catch (error) {
        this.$buefy.dialog.alert({
        title: "Error",
        message: error.message,
        type: "is-danger",
      });
      }
    },
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    }
  },
};
</script>