import Vue from 'vue'

const form = {
  namespaced: true,
  state: {
    form: {},
    images: {},
    token: null,
    report: {},
    project: {},
    client: {}
  },
  mutations: {
    addAnswer(state, field) {
      Vue.set(state.form, field.item, field);
    },
    setImages(state, { item, images }) {
      Vue.set(state.images, item, images)
    },
    setReport(state, report){
      Vue.set(state.report, report.data, report);
    },
    setProject(state, project){
      Vue.set(state.project, project.data, project);
    },
    setClient(state, client){
      Vue.set(state.client, client.data, client);
    },
    setToken(state, token) {
      state.token = `Bearer ${token}`;
      localStorage.setItem('token', `Bearer ${token}`);
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token);
    }
  },
  getters: {
    isFilled: state => Object.values(state.form).every(e => e.value !== null),
    allAnswers: state => Object.values(state.form),
    getImages: state => Object.values(state.images),
    getProject: state => Object.values(state.project),
    getReport: state => Object.values(state.report),
    getClient: state => Object.values(state.client),
    getSignatures: state => Object.values(state.form).filter(e => e.isSignature || false),
    getToken: state => state.token,
    isLoggedIn: state => state.token !== null,
  }
}

export default form