import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginView from '../views/LoginView.vue';
import store from '@/store/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    beforeEnter: async (to, from, next) => {
      if (store.getters['auth/isLogged']) {
        next({ name: 'Form' })
        return
      }
      next()
    }
  },
  {
    path: '/form',
    name: 'Form',
    component: () => import(/* webPackChunkName: "form" */ '@/views/FormView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import(/* webpackChunkName: "about" */ '../views/SuccessView.vue')
  },
  {
    path: '/dorothy/:magic',
    name: 'Dorothy',
    beforeEnter: async (to, from, next) => {
      if (store.getters['auth/isLogged']) {
        next({ name: 'Form' })
        return
      }
      next()
      try {
        await store.dispatch('auth/authenticate', { login_hash: to.params.magic })
        if (store.getters['auth/isLogged']) {
          next({ name: 'Form' })
          return
        }
        next({ name: 'Login' })
      } catch (e) {
        next({ name: 'Login' })
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isLogged']) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

export default router